const firebaseConfig = {
  apiKey: "AIzaSyBB0H47AhMkw9WZdQ86qraTGL5MUbbTbzQ",
  authDomain: "lms-rendezvous.firebaseapp.com",
  projectId: "lms-rendezvous",
  storageBucket: "lms-rendezvous.appspot.com",
  messagingSenderId: "418812270115",
  appId: "1:418812270115:web:1796e00e4157d112e28396"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBgPMKyA-yMtLwEdfLVA5lVSnUfW-L8phw",
//   authDomain: "rendezvous-677ac.firebaseapp.com",
//   projectId: "rendezvous-677ac",
//   storageBucket: "rendezvous-677ac.appspot.com",
//   messagingSenderId: "340094892380",
//   appId: "1:340094892380:web:6ff3b4641b1599ff786999"
// };
  
export default firebaseConfig;
// const firebaseConfig = {
//   apiKey: "AIzaSyBdfY8SyxM9VVeBWQGPEJ9bEF_cnarDy38",
//   authDomain: "rendezvous-lms.firebaseapp.com",
//   projectId: "rendezvous-lms",
//   storageBucket: "rendezvous-lms.appspot.com",
//   messagingSenderId: "739317264672",
//   appId: "1:739317264672:web:03df8338d0856444be6745",
//   measurementId: "G-0NWDZY23VN"
// };
  
// export default firebaseConfig;